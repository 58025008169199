import {
  UPDATE_HTTP_STATUS,
  RESET_HTTP_STATUS
} from '../../constants/ActionsType';

export const updateHttpStatus = status => {
  return { type: UPDATE_HTTP_STATUS, status };
};
export function resetHttpStatus() {
  return { type: RESET_HTTP_STATUS };
}
