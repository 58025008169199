import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import API from '../../api/index';
import { regenerateMenuData } from '../../common/menu';
import { getFirstMenu } from '../../utils/menu';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  UPDATE_MENU_REQUEST
} from '../../constants/ActionsType';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  updateToken,
  updateMenuSuccess
} from '../actions/user';

function loginFetch(username, password, uuid, code) {
  return API.Login({
    username,
    password,
    uuid,
    code
  });
}

function logoutFetch() {
  return API.Logout();
}

function menuRequest() {
  return API.menusBuild();
}

function* loginRequest(action) {
  const {
    payload: { userName, password, uuid, code },
    callback
  } = action;
  try {
    const response = yield call(loginFetch, userName, password, uuid, code);

    if (response.code === 10000) {
      message.success('登录成功！');
      console.log(response.data);
      yield put(updateToken(response.data.token));
      const menuRes = yield call(menuRequest);
      const menu = regenerateMenuData(menuRes.data);
      const jumpUrl = getFirstMenu('/', menu);
      const {
        token,
        user: { type, roles, avatar }
      } = response.data;
      yield put(
        loginSuccess({
          token,
          userName,
          menuPermissions: menu,
          type,
          jumpUrl,
          roles,
          avatar
        })
      );
    } else if (response.code === 10011) {
      yield put(loginFailure(404, '验证码已过期！'));
      if (callback) {
        callback();
      }
    } else if (response.code === 10012) {
      yield put(loginFailure(404, '验证码错误！'));
    } else if (response.code === 10013) {
      yield put(loginFailure(404, '用户名或密码错误！'));
      if (callback) {
        callback();
      }
    } else if (response.code === 10014) {
      yield put(loginFailure(404, '账号已停用，请联系管理员！'));
      if (callback) {
        callback();
      }
    } else {
      yield put(loginFailure(response.code, response.message));
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    yield put(loginFailure(404, '登录失败'));
    if (callback) {
      callback();
    }
  }
}

function* logoutRequest() {
  try {
    const res = yield call(logoutFetch);
    if (res.code === 10000) {
      yield put(logoutSuccess());
    }
  } catch (e) {
    message.error('退出登录失败！');
  }
}

function* updateMenuRequest() {
  try {
    const res = yield call(menuRequest);
    if (res.code === 10000) {
      const menu = regenerateMenuData(res.data);
      yield put(updateMenuSuccess(menu));
    }
  } catch (e) {
    message.error('更新菜单失败！');
  }
}

export default function* root() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginRequest),
    takeLatest(LOGOUT_REQUEST, logoutRequest),
    takeLatest(UPDATE_MENU_REQUEST, updateMenuRequest)
  ]);
}
