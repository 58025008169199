import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  APP_MODEL_LIST_REQUEST,
  APP_MODEL_ADD_REQUEST,
  APP_MODEL_UPDATE_REQUEST,
  APP_MODEL_REMOVE_REQUEST,
} from '../../constants/ActionsType';
import { appModelListSuccess, appModelListError } from '../actions/app-model';

function appModelFetch(data) {
  return API.appModelList(data);
}
function appModelAdd(data) {
  return API.appModelAdd(data);
}
function appModelRemove(id) {
  return API.appModelDel(id);
}
function appModelUpdate({ id, data }) {
  return API.appModelEdit(id, data);
}
function* appModelList(action) {
  const { callback } = action;
  try {
    const res = yield call(appModelFetch, action.payload);

    if (res.code === 10000) {
      yield put(appModelListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(appModelListError(res.message));
    }
  } catch (e) {
    yield put(appModelListError('获取机型信息数据出错'));
  }
}
function* appModelRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(appModelRemove, payload);
    if (res.code === 10000) {
      message.success(`删除机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除机型操作失败！`);
  }
}
function* appModelUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(appModelUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑机型操作失败！`);
  }
}
function* appModelAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(appModelAdd, payload);

    if (res.code === 10000) {
      message.success(`添加机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加机型操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(APP_MODEL_LIST_REQUEST, appModelList),
    takeLatest(APP_MODEL_ADD_REQUEST, appModelAddRequest),
    takeLatest(APP_MODEL_UPDATE_REQUEST, appModelUpdateRequest),
    takeLatest(APP_MODEL_REMOVE_REQUEST, appModelRemoveRequest),
  ]);
}
