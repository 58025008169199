import {
  APP_MODEL_LIST_REQUEST,
  APP_MODEL_LIST_SUCCESS,
  APP_MODEL_LIST_FAILURE,
  APP_MODEL_ADD_REQUEST,
  APP_MODEL_ADD_SUCCESS,
  APP_MODEL_UPDATE_REQUEST,
  APP_MODEL_UPDATE_SUCCESS,
  APP_MODEL_REMOVE_REQUEST,
  APP_MODEL_REMOVE_SUCCESS,
  APP_MODEL_RESET_REQUEST,
} from '../../constants/ActionsType';

export const appModelListRequest = (data, callback) => {
  return { type: APP_MODEL_LIST_REQUEST, payload: data, callback };
};
export const appModelListSuccess = (data) => {
  return { type: APP_MODEL_LIST_SUCCESS, payload: data };
};
export const appModelListError = (data) => {
  return { type: APP_MODEL_LIST_FAILURE, payload: data };
};
export const appModelAddRequest = (data, callback) => {
  return { type: APP_MODEL_ADD_REQUEST, payload: data, callback };
};
export const appModelAddSuccess = (data) => {
  return { type: APP_MODEL_ADD_SUCCESS, payload: data };
};
export const appModelUpdateRequest = (data, callback) => {
  return { type: APP_MODEL_UPDATE_REQUEST, payload: data, callback };
};
export const appModelUpdateSuccess = (data) => {
  return { type: APP_MODEL_UPDATE_SUCCESS, payload: data };
};
export const appModelRemoveRequest = (data, callback) => {
  return { type: APP_MODEL_REMOVE_REQUEST, payload: data, callback };
};
export const appModelRemoveSuccess = (data) => {
  return { type: APP_MODEL_REMOVE_SUCCESS, payload: data };
};
export const appModelResetRequest = () => {
  return { type: APP_MODEL_RESET_REQUEST };
};
