import {
  POSTER_MODEL_LIST_REQUEST,
  POSTER_MODEL_LIST_SUCCESS,
  POSTER_MODEL_LIST_FAILURE,
  POSTER_MODEL_ADD_REQUEST,
  POSTER_MODEL_ADD_SUCCESS,
  POSTER_MODEL_UPDATE_REQUEST,
  POSTER_MODEL_UPDATE_SUCCESS,
  POSTER_MODEL_REMOVE_REQUEST,
  POSTER_MODEL_REMOVE_SUCCESS,
  POSTER_MODEL_RESET_REQUEST,
} from '../../constants/ActionsType';

export const posterModelListRequest = (data, callback) => {
  return { type: POSTER_MODEL_LIST_REQUEST, payload: data, callback };
};
export const posterModelListSuccess = (data) => {
  return { type: POSTER_MODEL_LIST_SUCCESS, payload: data };
};
export const posterModelListError = (data) => {
  return { type: POSTER_MODEL_LIST_FAILURE, payload: data };
};
export const posterModelAddRequest = (data, callback) => {
  return { type: POSTER_MODEL_ADD_REQUEST, payload: data, callback };
};
export const posterModelAddSuccess = (data) => {
  return { type: POSTER_MODEL_ADD_SUCCESS, payload: data };
};
export const posterModelUpdateRequest = (data, callback) => {
  return { type: POSTER_MODEL_UPDATE_REQUEST, payload: data, callback };
};
export const posterModelUpdateSuccess = (data) => {
  return { type: POSTER_MODEL_UPDATE_SUCCESS, payload: data };
};
export const posterModelRemoveRequest = (data, callback) => {
  return { type: POSTER_MODEL_REMOVE_REQUEST, payload: data, callback };
};
export const posterModelRemoveSuccess = (data) => {
  return { type: POSTER_MODEL_REMOVE_SUCCESS, payload: data };
};
export const posterModelResetRequest = () => {
  return { type: POSTER_MODEL_RESET_REQUEST };
};
