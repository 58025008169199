import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_TOKEN,
  UPDATE_LOGO,
  UPDATE_AVATAR,
  UPDATE_MENU_SUCCESS,
} from '../../constants/ActionsType';
import { message } from 'antd';
const logoResource = require('../../assets/imgs/logo-sider.jpg');

const initState = {
  token: null,
  authenticated: false,
  isAuthenticating: false,
  statusText: '',
  userName: '',
  menuPermissions: [],
  jumpUrl: '/',
  redirect: null,
  roles: [],
  logo: logoResource,
  avatar: '',
  shortName: '软件管理平台',
  type: 0,
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case LOGIN_SUCCESS:
      const { userName, menuPermissions, jumpUrl, type, roles, avatar } = action.payload;
      return {
        ...state,
        authenticated: true,
        isAuthenticating: false,
        userName,
        menuPermissions,
        jumpUrl,
        redirect: null,
        type,
        roles,
        avatar,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initState,
        redirect: window.location.href,
      };
    case LOGIN_FAILURE:
      message.error(action.msg);
      return {
        ...state,
        ...initState,
        isAuthenticating: false,
        redirect: action.redirect ? action.redirect : null,
      };
    case UPDATE_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case UPDATE_MENU_SUCCESS:
      return {
        ...state,
        menuPermissions: action.payload,
      };
    case UPDATE_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    default:
      return state;
  }
}
