import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import PrivateRoute from './PrivateRoute';
import ServerErr from '../routes/NotFound/500';
import NoAuth from '../routes/NotFound/403';
import { getRouterData } from '../common/router';
import { ROUTE_BASE } from '../utils/config';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';

const routerData = getRouterData();
const LoginLayout = routerData['/login'].component;
const BasicLayout = routerData['/'].component;
function Layout() {
  return (
    <Switch>
      <Route path="/" component={BasicLayout} />
    </Switch>
  );
}
const routes = props => {
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter basename={ROUTE_BASE}>
        <Switch>
          <Route exact path="/login" component={LoginLayout} />
          <Route exact path="/exception/403" component={NoAuth} />
          <Route exact path="/exception/500" component={ServerErr} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default routes;
