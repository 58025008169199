export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_LOGO = 'UPDATE_LOGO';
export const UPDATE_MENU_REQUEST = 'UPDATE_MENU_REQUEST';
export const UPDATE_MENU_SUCCESS = 'UPDATE_MENU_SUCCESS';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// 设置menu菜单选中
export const DEFAULTITEM = 'DEFAULTITEM';
export const OPENKEYS = 'OPENKEYS';
export const MENUFOLD = 'MENUFOLD';
export const MENUCLEAR = 'MENUCLEAR';

export const UPDATE_HTTP_STATUS = 'UPDATE_HTTP_STATUS';
export const RESET_HTTP_STATUS = 'RESET_HTTP_STATUS';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const RESOURCES_LIST_REQUEST = 'RESOURCES_LIST_REQUEST';
export const RESOURCES_LIST_SUCCESS = 'RESOURCES_LIST_SUCCESS';
export const RESOURCES_LIST_FAILURE = 'RESOURCES_LIST_FAILURE';
export const RESOURCES_ADD_REQUEST = 'RESOURCES_ADD_REQUEST';
export const RESOURCES_UPDATE_REQUEST = 'RESOURCES_UPDATE_REQUEST';
export const RESOURCES_REMOVE_REQUEST = 'RESOURCES_REMOVE_REQUEST';
export const RESOURCES_ADD_SUCCESS = 'RESOURCES_ADD_SUCCESS';
export const RESOURCES_UPDATE_SUCCESS = 'RESOURCES_UPDATE_SUCCESS';
export const RESOURCES_REMOVE_SUCCESS = 'RESOURCES_REMOVE_SUCCESS';

export const ROLES_LIST_REQUEST = 'ROLES_LIST_REQUEST';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_FAILURE = 'ROLES_LIST_FAILURE';
export const ROLES_ADD_REQUEST = 'ROLES_ADD_REQUEST';
export const ROLES_UPDATE_REQUEST = 'ROLES_UPDATE_REQUEST';
export const ROLES_REMOVE_REQUEST = 'ROLES_REMOVE_REQUEST';
export const ROLES_ADD_SUCCESS = 'ROLES_ADD_SUCCESS';
export const ROLES_UPDATE_SUCCESS = 'ROLES_UPDATE_SUCCESS';
export const ROLES_REMOVE_SUCCESS = 'ROLES_REMOVE_SUCCESS';

export const PERMISSIONS_LIST_REQUEST = 'PERMISSIONS_LIST_REQUEST';
export const PERMISSIONS_LIST_SUCCESS = 'PERMISSIONS_LIST_SUCCESS';
export const PERMISSIONS_LIST_FAILURE = 'PERMISSIONS_LIST_FAILURE';
export const PERMISSIONS_ADD_REQUEST = 'PERMISSIONS_ADD_REQUEST';
export const PERMISSIONS_UPDATE_REQUEST = 'PERMISSIONS_UPDATE_REQUEST';
export const PERMISSIONS_REMOVE_REQUEST = 'PERMISSIONS_REMOVE_REQUEST';
export const PERMISSIONS_ADD_SUCCESS = 'PERMISSIONS_ADD_SUCCESS';
export const PERMISSIONS_UPDATE_SUCCESS = 'PERMISSIONS_UPDATE_SUCCESS';
export const PERMISSIONS_REMOVE_SUCCESS = 'PERMISSIONS_REMOVE_SUCCESS';
export const PERMISSIONS_TREE_LIST_REQUEST = 'PERMISSIONS_TREE_LIST_REQUEST';
export const PERMISSIONS_TREE_LIST_SUCCESS = 'PERMISSIONS_TREE_LIST_SUCCESS';
export const PERMISSIONS_TREE_LIST_FAILURE = 'PERMISSIONS_TREE_LIST_FAILURE';

export const ACTIVITY_LIST_REQUEST = 'ACTIVITY_LIST_REQUEST';
export const ACTIVITY_LIST_SUCCESS = 'ACTIVITY_LIST_SUCCESS';
export const ACTIVITY_LIST_FAILURE = 'ACTIVITY_LIST_FAILURE';
export const ACTIVITY_ADD_REQUEST = 'ACTIVITY_ADD_REQUEST';
export const ACTIVITY_UPDATE_REQUEST = 'ACTIVITY_UPDATE_REQUEST';
export const ACTIVITY_REMOVE_REQUEST = 'ACTIVITY_REMOVE_REQUEST';
export const ACTIVITY_ADD_SUCCESS = 'ACTIVITY_ADD_SUCCESS';
export const ACTIVITY_UPDATE_SUCCESS = 'ACTIVITY_UPDATE_SUCCESS';
export const ACTIVITY_REMOVE_SUCCESS = 'ACTIVITY_REMOVE_SUCCESS';

export const VENDOR_LIST_REQUEST = 'VENDOR_LIST_REQUEST';
export const VENDOR_LIST_SUCCESS = 'VENDOR_LIST_SUCCESS';
export const VENDOR_LIST_FAILURE = 'VENDOR_LIST_FAILURE';
export const VENDOR_ADD_REQUEST = 'VENDOR_ADD_REQUEST';
export const VENDOR_UPDATE_REQUEST = 'VENDOR_UPDATE_REQUEST';
export const VENDOR_REMOVE_REQUEST = 'VENDOR_REMOVE_REQUEST';
export const VENDOR_ADD_SUCCESS = 'VENDOR_ADD_SUCCESS';
export const VENDOR_UPDATE_SUCCESS = 'VENDOR_UPDATE_SUCCESS';
export const VENDOR_REMOVE_SUCCESS = 'VENDOR_REMOVE_SUCCESS';

export const APP_TYPE_LIST_REQUEST = 'APP_TYPE_LIST_REQUEST';
export const APP_TYPE_LIST_SUCCESS = 'APP_TYPE_LIST_SUCCESS';
export const APP_TYPE_LIST_FAILURE = 'APP_TYPE_LIST_FAILURE';
export const APP_TYPE_ADD_REQUEST = 'APP_TYPE_ADD_REQUEST';
export const APP_TYPE_UPDATE_REQUEST = 'APP_TYPE_UPDATE_REQUEST';
export const APP_TYPE_REMOVE_REQUEST = 'APP_TYPE_REMOVE_REQUEST';
export const APP_TYPE_ADD_SUCCESS = 'APP_TYPE_ADD_SUCCESS';
export const APP_TYPE_UPDATE_SUCCESS = 'APP_TYPE_UPDATE_SUCCESS';
export const APP_TYPE_REMOVE_SUCCESS = 'APP_TYPE_REMOVE_SUCCESS';

export const APP_MODEL_LIST_REQUEST = 'APP_MODEL_LIST_REQUEST';
export const APP_MODEL_LIST_SUCCESS = 'APP_MODEL_LIST_SUCCESS';
export const APP_MODEL_LIST_FAILURE = 'APP_MODEL_LIST_FAILURE';
export const APP_MODEL_ADD_REQUEST = 'APP_MODEL_ADD_REQUEST';
export const APP_MODEL_UPDATE_REQUEST = 'APP_MODEL_UPDATE_REQUEST';
export const APP_MODEL_REMOVE_REQUEST = 'APP_MODEL_REMOVE_REQUEST';
export const APP_MODEL_ADD_SUCCESS = 'APP_MODEL_ADD_SUCCESS';
export const APP_MODEL_UPDATE_SUCCESS = 'APP_MODEL_UPDATE_SUCCESS';
export const APP_MODEL_REMOVE_SUCCESS = 'APP_MODEL_REMOVE_SUCCESS';
export const APP_MODEL_RESET_REQUEST = 'APP_MODEL_RESET_REQUEST';

export const MENUS_LIST_REQUEST = 'MENUS_LIST_REQUEST';
export const MENUS_LIST_SUCCESS = 'MENUS_LIST_SUCCESS';
export const MENUS_LIST_FAILURE = 'MENUS_LIST_FAILURE';
export const MENUS_ADD_REQUEST = 'MENUS_ADD_REQUEST';
export const MENUS_UPDATE_REQUEST = 'MENUS_UPDATE_REQUEST';
export const MENUS_REMOVE_REQUEST = 'MENUS_REMOVE_REQUEST';
export const MENUS_ADD_SUCCESS = 'MENUS_ADD_SUCCESS';
export const MENUS_UPDATE_SUCCESS = 'MENUS_UPDATE_SUCCESS';
export const MENUS_REMOVE_SUCCESS = 'MENUS_TREE_LIST_REQUEST';
export const MENUS_TREE_LIST_REQUEST = 'MENUS_TREE_LIST_REQUEST';
export const MENUS_TREE_LIST_SUCCESS = 'MENUS_TREE_LIST_SUCCESS';
export const MENUS_TREE_LIST_FAILURE = 'MENUS_TREE_LIST_FAILURE';

export const POSTER_MODEL_LIST_REQUEST = 'POSTER_MODEL_LIST_REQUEST';
export const POSTER_MODEL_LIST_SUCCESS = 'POSTER_MODEL_LIST_SUCCESS';
export const POSTER_MODEL_LIST_FAILURE = 'POSTER_MODEL_LIST_FAILURE';
export const POSTER_MODEL_ADD_REQUEST = 'POSTER_MODEL_ADD_REQUEST';
export const POSTER_MODEL_UPDATE_REQUEST = 'POSTER_MODEL_UPDATE_REQUEST';
export const POSTER_MODEL_REMOVE_REQUEST = 'POSTER_MODEL_REMOVE_REQUEST';
export const POSTER_MODEL_ADD_SUCCESS = 'POSTER_MODEL_ADD_SUCCESS';
export const POSTER_MODEL_UPDATE_SUCCESS = 'POSTER_MODEL_UPDATE_SUCCESS';
export const POSTER_MODEL_REMOVE_SUCCESS = 'POSTER_MODEL_REMOVE_SUCCESS';
export const POSTER_MODEL_RESET_REQUEST = 'POSTER_MODEL_RESET_REQUEST';

export const POSTER_VENDOR_LIST_REQUEST = 'POSTER_VENDOR_LIST_REQUEST';
export const POSTER_VENDOR_LIST_SUCCESS = 'POSTER_VENDOR_LIST_SUCCESS';
export const POSTER_VENDOR_LIST_FAILURE = 'POSTER_VENDOR_LIST_FAILURE';
export const POSTER_VENDOR_ADD_REQUEST = 'POSTER_VENDOR_ADD_REQUEST';
export const POSTER_VENDOR_UPDATE_REQUEST = 'POSTER_VENDOR_UPDATE_REQUEST';
export const POSTER_VENDOR_REMOVE_REQUEST = 'POSTER_VENDOR_REMOVE_REQUEST';
export const POSTER_VENDOR_ADD_SUCCESS = 'POSTER_VENDOR_ADD_SUCCESS';
export const POSTER_VENDOR_UPDATE_SUCCESS = 'POSTER_VENDOR_UPDATE_SUCCESS';
export const POSTER_VENDOR_REMOVE_SUCCESS = 'POSTER_VENDOR_REMOVE_SUCCESS';
export const POSTER_VENDOR_RESET_REQUEST = 'POSTER_VENDOR_RESET_REQUEST';
