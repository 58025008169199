import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetHttpStatus } from '../redux/actions/network';

class PrivateRoute extends React.Component {
  render() {
    const {
      path,
      component,
      authenticated,
      httpStatus,
      redirect,
      ...rest
    } = this.props;
    var PUBLIC_ROOT = '/login';

    if (!authenticated) {
      return (
        <Redirect
          to={
            redirect
              ? `${PUBLIC_ROOT}?redirect=${encodeURIComponent(redirect)}`
              : `${PUBLIC_ROOT}?redirect=${encodeURIComponent(
                  window.location.href
                )}`
          }
        />
      );
    } else if (httpStatus === 403) {
      return <Redirect to="/exception/403" />;
    } else if (httpStatus === 500) {
      return <Redirect to="/exception/500" />;
    } else {
      return <Route exact path={path} component={component} {...rest} />;
    }
  }
}
const mapStateToProps = state => ({
  authenticated: state.userReducer.authenticated,
  httpStatus: state.networkReducer.httpStatus,
  redirect: state.userReducer.redirect
});
const mapDispatchToProps = dispatch => ({
  resetHttpStatus: () => {
    dispatch(resetHttpStatus());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
