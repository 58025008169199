import {
  MENUS_LIST_REQUEST,
  MENUS_LIST_SUCCESS,
  MENUS_LIST_FAILURE,
  MENUS_ADD_SUCCESS,
  MENUS_UPDATE_SUCCESS,
  MENUS_REMOVE_SUCCESS,
  MENUS_TREE_LIST_SUCCESS,
  MENUS_TREE_LIST_FAILURE
} from '../../constants/ActionsType';
import { message } from 'antd';

const initState = {
  list: [],
  tree: [],
  loading: false
};

function menuReducer(state = initState, action) {
  switch (action.type) {
    case MENUS_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case MENUS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    case MENUS_LIST_FAILURE:
      message.error(action.payload);
      return {
        ...state,
        loading: false
      };
    case MENUS_REMOVE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload.id)
      };
    case MENUS_ADD_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload)
      };
    case MENUS_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            Object.keys(action.payload).forEach(key => {
              item[key] = action.payload[key];
            });
            return item;
          }
          return item;
        })
      };
    case MENUS_TREE_LIST_SUCCESS:
      return {
        ...state,
        tree: action.payload
      };
    case MENUS_TREE_LIST_FAILURE:
      message.error(action.payload);
      return {
        ...state
      };
    default:
      return state;
  }
}

export default menuReducer;
