import {
  ROLES_LIST_REQUEST,
  ROLES_LIST_SUCCESS,
  ROLES_LIST_FAILURE,
  ROLES_ADD_SUCCESS,
  ROLES_UPDATE_SUCCESS,
  ROLES_REMOVE_SUCCESS
} from '../../constants/ActionsType';
import { message } from 'antd';

const initState = {
  list: [],
  total: 0,
  loading: false
};

function roleReducer(state = initState, action) {
  switch (action.type) {
    case ROLES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ROLES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        loading: false,
        total: action.payload.total
      };
    case ROLES_LIST_FAILURE:
      message.error(action.payload);
      return {
        ...state,
        loading: false
      };
    case ROLES_REMOVE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload.id),
        total: state.total - 1
      };
    case ROLES_ADD_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload)
      };
    case ROLES_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            Object.keys(action.payload).forEach(key => {
              item[key] = action.payload[key];
            });
            return item;
          }
          return item;
        })
      };
    default:
      return state;
  }
}

export default roleReducer;
