import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  PERMISSIONS_LIST_REQUEST,
  PERMISSIONS_ADD_REQUEST,
  PERMISSIONS_UPDATE_REQUEST,
  PERMISSIONS_REMOVE_REQUEST,
  PERMISSIONS_TREE_LIST_REQUEST
} from '../../constants/ActionsType';
import {
  permissionsListSuccess,
  permissionsListError,
  permissionsTreeListError,
  permissionsTreeListSuccess
} from '../actions/permission';

function permissionsFetch(data) {
  return API.permissionsList(data);
}
function permissionsTreeFetch() {
  return API.permissionsTree();
}
function permissionsAdd(data) {
  return API.permissionsAdd(data);
}
function permissionsRemove(id) {
  return API.permissionsDel(id);
}
function permissionsUpdate({ id, data }) {
  return API.permissionsUpdate(id, data);
}
function* permissionsList(action) {
  const { callback } = action;
  try {
    const res = yield call(permissionsFetch, action.payload);

    if (res.code === 10000) {
      yield put(permissionsListSuccess(res.data.content));
      if (callback) {
        callback();
      }
    } else {
      yield put(permissionsListError(res.message));
    }
  } catch (e) {
    yield put(permissionsListError('获取权限信息数据出错'));
  }
}
function* permissionsTree() {
  try {
    const res = yield call(permissionsTreeFetch);

    if (res.code === 10000) {
      yield put(permissionsTreeListSuccess(res.data));
    } else {
      yield put(permissionsTreeListError(res.message));
    }
  } catch (e) {
    yield put(permissionsTreeListError('获取权限信息数据出错'));
  }
}

function* permissionsRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(permissionsRemove, payload);
    if (res.code === 10000) {
      message.success(`删除权限操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除权限操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除权限操作失败！`);
  }
}
function* permissionsUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(permissionsUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑权限操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑权限操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑权限操作失败！`);
  }
}
function* permissionsAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(permissionsAdd, payload);

    if (res.code === 10000) {
      message.success(`添加权限操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加权限操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加权限操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(PERMISSIONS_LIST_REQUEST, permissionsList),
    takeLatest(PERMISSIONS_ADD_REQUEST, permissionsAddRequest),
    takeLatest(PERMISSIONS_UPDATE_REQUEST, permissionsUpdateRequest),
    takeLatest(PERMISSIONS_REMOVE_REQUEST, permissionsRemoveRequest),
    takeLatest(PERMISSIONS_TREE_LIST_REQUEST, permissionsTree)
  ]);
}
