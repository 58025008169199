import request from '../http/index';
import { API_ROOT, UPLOAD_ROOT, API_AUTH, Api } from '../constants/ApiConfig';

export default {
  Code() {
    return request(`${API_AUTH}${Api.Code}`, {
      method: 'get',
    });
  },
  Login(data) {
    return request(`${API_AUTH}${Api.Login}`, {
      method: 'post',
      data,
    });
  },
  Logout() {
    return request(`${API_AUTH}${Api.Logout}`, {
      method: 'post',
    });
  },
  menusList(data) {
    return request(`${API_ROOT}${Api.Menus}`, {
      method: 'get',
      params: data,
    });
  },
  menusTree(data) {
    return request(`${API_ROOT}${Api.MenusTree}`, {
      method: 'get',
      params: data,
    });
  },
  menusBuild(data) {
    return request(`${API_ROOT}${Api.MenusBuild}`, {
      method: 'get',
      params: data,
    });
  },
  menusAdd(data) {
    return request(`${API_ROOT}${Api.Menus}`, {
      method: 'post',
      data,
    });
  },
  menusUpdate(id, data) {
    return request(`${API_ROOT}${Api.Menus}/${id}`, {
      method: 'put',
      data,
    });
  },
  menusDel(id) {
    return request(`${API_ROOT}${Api.Menus}/${id}`, {
      method: 'delete',
    });
  },
  accountsList(data) {
    return request(`${API_ROOT}${Api.Account}`, {
      method: 'get',
      params: data,
    });
  },
  accountsAdd(data) {
    return request(`${API_ROOT}${Api.Account}`, {
      method: 'post',
      data,
    });
  },
  accountsInfo(data) {
    return request(`${API_ROOT}${Api.AccountInfo}`, {
      method: 'get',
      params: data,
    });
  },
  accountsUpdate(id, data) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: 'put',
      data,
    });
  },
  accountsDel(id) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: 'delete',
    });
  },

  usersList(data) {
    return request(`${API_ROOT}${Api.Users}`, {
      method: 'get',
      params: data,
    });
  },
  usersCount() {
    return request(`${API_ROOT}${Api.Users}/count`, {
      method: 'get',
    });
  },
  usersById(id) {
    return request(`${API_ROOT}${Api.Users}/${id}`, {
      method: 'get',
    });
  },
  usersAdd(data) {
    return request(`${API_ROOT}${Api.Users}`, {
      method: 'post',
      data,
    });
  },
  usersUpdate(id, data) {
    return request(`${API_ROOT}${Api.Users}/${id}`, {
      method: 'put',
      data,
    });
  },
  usersDel(id) {
    return request(`${API_ROOT}${Api.Users}/${id}`, {
      method: 'delete',
    });
  },
  usersIntegral(data) {
    return request(`${API_ROOT}${Api.Users}/integral/batch`, {
      method: 'post',
      data,
    });
  },
  usersRecordsByWeek(id, data) {
    return request(`${API_ROOT}${Api.Users}/runningRecord/daily/${id}`, {
      method: 'get',
      params: data,
    });
  },
  permissionsList(data) {
    return request(`${API_ROOT}${Api.Permissions}`, {
      method: 'get',
      params: data,
    });
  },
  permissionsTree() {
    return request(`${API_ROOT}${Api.PermissionsTree}`, {
      method: 'get',
    });
  },
  permissionsAdd(data) {
    return request(`${API_ROOT}${Api.Permissions}`, {
      method: 'post',
      data,
    });
  },
  permissionsUpdate(id, data) {
    return request(`${API_ROOT}${Api.Permissions}/${id}`, {
      method: 'put',
      data,
    });
  },
  permissionsDel(id) {
    return request(`${API_ROOT}${Api.Permissions}/${id}`, {
      method: 'delete',
    });
  },
  rolesList(data) {
    return request(`${API_ROOT}${Api.Roles}`, {
      method: 'get',
      params: data,
    });
  },
  rolesTree(data) {
    return request(`${API_ROOT}${Api.RolesTree}`, {
      method: 'get',
      params: data,
    });
  },
  rolesAllList(data) {
    return request(`${API_ROOT}${Api.Roles}`, {
      method: 'get',
      params: data,
    });
  },
  rolesAdd(data) {
    return request(`${API_ROOT}${Api.Roles}`, {
      method: 'post',
      data,
    });
  },
  rolesUpdate(id, data) {
    return request(`${API_ROOT}${Api.Roles}/${id}`, {
      method: 'put',
      data,
    });
  },
  rolesMenuUpdate(id, data) {
    return request(`${API_ROOT}${Api.Roles}/${id}/menu`, {
      method: 'put',
      data,
    });
  },
  rolesPermissionUpdate(id, data) {
    return request(`${API_ROOT}${Api.Roles}/${id}/permission`, {
      method: 'put',
      data,
    });
  },
  rolesDel(id) {
    return request(`${API_ROOT}${Api.Roles}/${id}`, {
      method: 'delete',
    });
  },
  setPassword(newPassword, oldPassword) {
    return request(`${API_ROOT}${Api.Password}/${newPassword}/${oldPassword}`, {
      method: 'post',
    });
  },
  resetPassword(id) {
    return request(`${API_ROOT}/sysUsers/resetPass/${id}`, {
      method: 'post',
    });
  },
  updateAvatar(url) {
    return request(`${API_ROOT}/sysUsers/updateAvatar?url=${url}`, {
      method: 'post',
    });
  },
  accountList(data) {
    return request(`${API_ROOT}${Api.Account}`, {
      method: 'get',
      params: data,
    });
  },
  accountById(id) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: 'get',
    });
  },
  accountAdd(data) {
    return request(`${API_ROOT}${Api.Account}`, {
      method: 'post',
      data,
    });
  },
  accountUpdate(id, data) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: 'put',
      data,
    });
  },
  accountDel(id) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: 'delete',
    });
  },

  activityList(data) {
    return request(`${API_ROOT}${Api.Activity}`, {
      method: 'get',
      params: data,
    });
  },
  activityCount(type, id) {
    return request(`${API_ROOT}${Api.Activity}/count/${type}/${id}`, {
      method: 'get',
    });
  },
  activityRankList(id, data) {
    return request(`${API_ROOT}${Api.Activity}/cid/${id}`, {
      method: 'get',
      params: data,
    });
  },
  activityUsersRankList(id, data) {
    return request(`${API_ROOT}${Api.Activity}/rank/${id}`, {
      method: 'get',
      params: data,
    });
  },
  activityCommentsList(id, data) {
    return request(`${API_ROOT}${Api.Activity}/${id}/comments`, {
      method: 'get',
      params: data,
    });
  },
  activityCommentsDelById(id) {
    return request(`${API_ROOT}${Api.Activity}/comments/${id}`, {
      method: 'delete',
    });
  },
  activityByMonth(data) {
    return request(`${API_ROOT}${Api.Activity}/count/month`, {
      method: 'get',
      params: data,
    });
  },
  activitySignedCount(id) {
    return request(`${API_ROOT}${Api.Activity}/${id}/signed`, {
      method: 'get',
    });
  },
  activityById(id, data) {
    return request(`${API_ROOT}${Api.Activity}/${id}`, {
      method: 'get',
      params: data,
    });
  },

  activityAdd(data) {
    return request(`${API_ROOT}${Api.Activity}`, {
      method: 'post',
      data,
    });
  },
  activityUpdate(id, data) {
    return request(`${API_ROOT}${Api.Activity}/${id}`, {
      method: 'put',
      data,
    });
  },
  activityDel(id) {
    return request(`${API_ROOT}${Api.Activity}/${id}`, {
      method: 'delete',
    });
  },
  activityByRank(id, data) {
    return request(`${API_ROOT}${Api.Activity}/rank/${id}`, {
      method: 'post',
      data,
    });
  },
  activityLike(id) {
    return request(`${API_ROOT}${Api.Activity}/top/${id}`, {
      method: 'post',
    });
  },
  activityDisLike(id) {
    return request(`${API_ROOT}${Api.Activity}/removeTop/${id}`, {
      method: 'post',
    });
  },
  resourcesList(data) {
    return request(`${API_ROOT}${Api.Resources}`, {
      method: 'get',
      params: data,
    });
  },
  resourcesListByCid(id, data) {
    return request(`${API_ROOT}${Api.Resources}/cid/${id}`, {
      method: 'get',
      params: data,
    });
  },
  resourcesListBySid(id, data) {
    return request(`${API_ROOT}${Api.Resources}/sid/${id}`, {
      method: 'get',
      params: data,
    });
  },
  resourcesListById(id, data) {
    return request(`${API_ROOT}${Api.Resources}/${id}`, {
      method: 'get',
      params: data,
    });
  },
  resourcesEdit(data) {
    return request(`${UPLOAD_ROOT}${Api.Resources}/update?${data}`, {
      method: 'post',
    });
  },
  resourcesDel(id) {
    return request(`${API_ROOT}${Api.Resources}/${id}`, {
      method: 'delete',
    });
  },

  vendorsList(data) {
    return request(`${API_ROOT}${Api.Vendors}`, {
      method: 'get',
      params: data,
    });
  },
  vendorsAdd(data) {
    return request(`${API_ROOT}${Api.Vendors}`, {
      method: 'post',
      data,
    });
  },
  vendorsById(id) {
    return request(`${API_ROOT}${Api.Vendors}/${id}`, {
      method: 'get',
    });
  },
  vendorsEdit(id, data) {
    return request(`${API_ROOT}${Api.Vendors}/${id}`, {
      method: 'put',
      data,
    });
  },
  vendorsDel(id) {
    return request(`${API_ROOT}${Api.Vendors}/${id}`, {
      method: 'delete',
    });
  },

  uploadResource(data) {
    return request(`${UPLOAD_ROOT}${Api.Resources}`, {
      method: 'post',
      data,
    });
  },

  updateList(data) {
    return request(`${API_ROOT}${Api.Update}/list`, {
      method: 'get',
      params: data,
    });
  },
  updateAdd(data) {
    return request(`${API_ROOT}${Api.Update}`, {
      method: 'post',
      data,
    });
  },
  updateEdit(id, data) {
    return request(`${API_ROOT}${Api.Update}/${id}`, {
      method: 'put',
      data,
    });
  },
  updateDel(id) {
    return request(`${API_ROOT}${Api.Update}/${id}`, {
      method: 'delete',
    });
  },
  updateStatus(id, status) {
    return request(`${API_ROOT}${Api.Update}/${id}/switch/${status}`, {
      method: 'post',
    });
  },
  updateByMac(id) {
    return request(`${API_ROOT}${Api.Update}/${id}/mac`, {
      method: 'get',
    });
  },
  updateByMacEdit(id, data) {
    return request(`${API_ROOT}${Api.Update}/${id}/mac`, {
      method: 'post',
      data,
    });
  },
  updateByMacDel(id) {
    return request(`${API_ROOT}${Api.Update}/${id}/mac`, {
      method: 'delete',
    });
  },
  updateByModel(id) {
    return request(`${API_ROOT}${Api.Update}/${id}/model`, {
      method: 'get',
    });
  },
  updateByModelEdit(id, data) {
    return request(`${API_ROOT}${Api.Update}/${id}/model`, {
      method: 'post',
      data,
    });
  },
  updateByStatistics(id, data) {
    return request(`${API_ROOT}${Api.Update}/${id}/statistics`, {
      method: 'get',
      params: data,
    });
  },
  updateByDaily(id, data) {
    return request(`${API_ROOT}${Api.Update}/${id}/daily`, {
      method: 'get',
      params: data,
    });
  },
  appTypeList(data) {
    return request(`${API_ROOT}${Api.AppType}`, {
      method: 'get',
      params: data,
    });
  },
  appTypeAdd(data) {
    return request(`${API_ROOT}${Api.AppType}`, {
      method: 'post',
      data,
    });
  },
  appTypeById(id) {
    return request(`${API_ROOT}${Api.AppType}/${id}`, {
      method: 'get',
    });
  },
  appTypeEdit(id, data) {
    return request(`${API_ROOT}${Api.AppType}/${id}`, {
      method: 'put',
      data,
    });
  },
  appTypeDel(id) {
    return request(`${API_ROOT}${Api.AppType}/${id}`, {
      method: 'delete',
    });
  },
  appModelList(data) {
    return request(`${API_ROOT}${Api.AppModel}`, {
      method: 'get',
      params: data,
    });
  },
  appModelAdd(data) {
    return request(`${API_ROOT}${Api.AppModel}`, {
      method: 'post',
      data,
    });
  },
  appModelById(id) {
    return request(`${API_ROOT}${Api.AppModel}/${id}`, {
      method: 'get',
    });
  },
  appModelEdit(id, data) {
    return request(`${API_ROOT}${Api.AppModel}/${id}`, {
      method: 'put',
      data,
    });
  },
  appModelDel(id) {
    return request(`${API_ROOT}${Api.AppModel}/${id}`, {
      method: 'delete',
    });
  },
  adList(data) {
    return request(`${API_ROOT}/ad/list`, {
      method: 'get',
      params: data,
    });
  },
  adAdd(data) {
    return request(`${API_ROOT}/ad/info`, {
      method: 'post',
      data,
    });
  },
  adDel(id) {
    return request(`${API_ROOT}/ad/${id}`, {
      method: 'delete',
    });
  },
  posterModelList(data) {
    return request(`${API_ROOT}/ad/model`, {
      method: 'get',
      params: data,
    });
  },
  posterModelAdd(data) {
    return request(`${API_ROOT}/ad/model`, {
      method: 'post',
      data,
    });
  },
  posterModelEdit(id, data) {
    return request(`${API_ROOT}/ad/model/${id}`, {
      method: 'put',
      data,
    });
  },
  posterModelDel(id) {
    return request(`${API_ROOT}/ad/model/${id}`, {
      method: 'delete',
    });
  },

  posterVendorList(data) {
    return request(`${API_ROOT}/ad/mfr`, {
      method: 'get',
      params: data,
    });
  },
  posterVendorAdd(data) {
    return request(`${API_ROOT}/ad/mfr`, {
      method: 'post',
      data,
    });
  },
  posterVendorEdit(id, data) {
    return request(`${API_ROOT}/ad/mfr/${id}`, {
      method: 'put',
      data,
    });
  },
  posterVendorDel(id) {
    return request(`${API_ROOT}/ad/mfr/${id}`, {
      method: 'delete',
    });
  },

  deviceLogList(data) {
    return request(`${API_ROOT}/device/log`, {
      method: 'get',
      params: data,
    });
  },
  deviceLogDel(ids) {
    return request(`${API_ROOT}/device/log/${ids}`, {
      method: 'delete',
    });
  },
  kugouAccountList(data) {
    return request(`${API_ROOT}/kugou/account/list`, {
      method: 'get',
      params: data,
    });
  },
  kugouAccountLogList(data) {
    return request(`${API_ROOT}/kugou/account/log/list`, {
      method: 'get',
      params: data,
    });
  },
};
