import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  POSTER_MODEL_LIST_REQUEST,
  POSTER_MODEL_ADD_REQUEST,
  POSTER_MODEL_UPDATE_REQUEST,
  POSTER_MODEL_REMOVE_REQUEST,
} from '../../constants/ActionsType';
import { posterModelListSuccess, posterModelListError } from '../actions/poster-model';

function posterModelFetch(data) {
  return API.posterModelList(data);
}
function posterModelAdd(data) {
  return API.posterModelAdd(data);
}
function posterModelRemove(id) {
  return API.posterModelDel(id);
}
function posterModelUpdate({ id, data }) {
  return API.posterModelEdit(id, data);
}
function* posterModelList(action) {
  const { callback } = action;
  try {
    const res = yield call(posterModelFetch, action.payload);

    if (res.code === 10000) {
      yield put(posterModelListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(posterModelListError(res.message));
    }
  } catch (e) {
    yield put(posterModelListError('获取机型信息数据出错'));
  }
}
function* posterModelRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(posterModelRemove, payload);
    if (res.code === 10000) {
      message.success(`删除机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除机型操作失败！`);
  }
}
function* posterModelUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(posterModelUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑机型操作失败！`);
  }
}
function* posterModelAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(posterModelAdd, payload);

    if (res.code === 10000) {
      message.success(`添加机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加机型操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(POSTER_MODEL_LIST_REQUEST, posterModelList),
    takeLatest(POSTER_MODEL_ADD_REQUEST, posterModelAddRequest),
    takeLatest(POSTER_MODEL_UPDATE_REQUEST, posterModelUpdateRequest),
    takeLatest(POSTER_MODEL_REMOVE_REQUEST, posterModelRemoveRequest),
  ]);
}
