import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  RESOURCES_LIST_REQUEST,
  RESOURCES_ADD_REQUEST,
  RESOURCES_UPDATE_REQUEST,
  RESOURCES_REMOVE_REQUEST
} from '../../constants/ActionsType';
import {
  resourcesListSuccess,
  resourcesListError,
  resourcesAddSuccess,
  resourcesUpdateSuccess,
  resourcesRemoveSuccess
} from '../actions/resources';

function resourcesFetch(data) {
  return API.resourcesList(data);
}
function resourcesAdd(data) {
  return API.resourcesAdd(data);
}
function resourcesRemove(id) {
  return API.resourcesDel(id);
}
function resourcesUpdate(data) {
  return API.resourcesEdit(data);
}
function* resourcesList(action) {
  const { callback } = action;
  try {
    const res = yield call(resourcesFetch, action.payload);

    if (res.code === 10000) {
      yield put(resourcesListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(resourcesListError(res.message));
    }
  } catch (e) {
    yield put(resourcesListError('获取素材信息数据出错'));
  }
}
function* resourcesRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(resourcesRemove, payload.id);
    if (res.code === 10000) {
      yield put(resourcesRemoveSuccess({ id: payload.id }));
      message.success(`删除素材操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除素材操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除素材操作失败！`);
  }
}
function* resourcesUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(resourcesUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑素材操作成功！`);
      yield put(
        resourcesUpdateSuccess({
          ...payload.data,
          optLock: res.data,
          id: payload.id
        })
      );
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑素材操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑素材操作失败！`);
  }
}
function* resourcesAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(resourcesAdd, payload);

    if (res.code === 10000) {
      yield put(
        resourcesAddSuccess({
          id: res.data,
          ...payload,
          optLock: 0
        })
      );
      message.success(`添加素材操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加素材操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加素材操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(RESOURCES_LIST_REQUEST, resourcesList),
    takeLatest(RESOURCES_ADD_REQUEST, resourcesAddRequest),
    takeLatest(RESOURCES_UPDATE_REQUEST, resourcesUpdateRequest),
    takeLatest(RESOURCES_REMOVE_REQUEST, resourcesRemoveRequest)
  ]);
}
