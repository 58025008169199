import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  MENUS_LIST_REQUEST,
  MENUS_ADD_REQUEST,
  MENUS_UPDATE_REQUEST,
  MENUS_REMOVE_REQUEST,
  MENUS_TREE_LIST_REQUEST
} from '../../constants/ActionsType';
import {
  menusListSuccess,
  menusListError,
  menusTreeListSuccess,
  menusTreeListError,
  menusAddSuccess
} from '../actions/menu';

function menusFetch(data) {
  return API.menusList(data);
}
function menusTreeFetch() {
  return API.menusTree();
}
function menusAdd(data) {
  return API.menusAdd(data);
}
function menusRemove(id) {
  return API.menusDel(id);
}
function menusUpdate({ id, data }) {
  return API.menusUpdate(id, data);
}
function* menusList(action) {
  const { callback } = action;
  try {
    const res = yield call(menusFetch, action.payload);

    if (res.code === 10000) {
      yield put(menusListSuccess(res.data.content));
      if (callback) {
        callback();
      }
    } else {
      yield put(menusListError(res.message));
    }
  } catch (e) {
    yield put(menusListError('获取菜单信息数据出错'));
  }
}
function* menusTreeRequest() {
  try {
    const res = yield call(menusTreeFetch);
    if (res.code === 10000) {
      yield put(menusTreeListSuccess(res.data));
    } else {
      yield put(menusTreeListError(res.message));
    }
  } catch (e) {
    yield put(menusTreeListError('获取菜单信息数据出错'));
  }
}

function* menusRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(menusRemove, payload);
    if (res.code === 10000) {
      // yield put(menusRemoveSuccess({ id: payload }));
      message.success(`删除菜单操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除菜单操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除菜单操作失败！`);
  }
}
function* menusUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(menusUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑菜单操作成功！`);
      // yield put(
      //   menusUpdateSuccess({
      //     ...payload.data,
      //     optLock: res.data,
      //     id: payload.id
      //   })
      // );
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑菜单操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑菜单操作失败！`);
  }
}
function* menusAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(menusAdd, payload);

    if (res.code === 10000) {
      yield put(
        menusAddSuccess({
          id: res.data,
          ...payload,
          optLock: 0
        })
      );
      message.success(`添加菜单操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加菜单操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加菜单操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(MENUS_LIST_REQUEST, menusList),
    takeLatest(MENUS_ADD_REQUEST, menusAddRequest),
    takeLatest(MENUS_UPDATE_REQUEST, menusUpdateRequest),
    takeLatest(MENUS_REMOVE_REQUEST, menusRemoveRequest),
    takeLatest(MENUS_TREE_LIST_REQUEST, menusTreeRequest)
  ]);
}
