import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  APP_TYPE_LIST_REQUEST,
  APP_TYPE_ADD_REQUEST,
  APP_TYPE_UPDATE_REQUEST,
  APP_TYPE_REMOVE_REQUEST,
} from '../../constants/ActionsType';
import { appTypeListSuccess, appTypeListError } from '../actions/app-type';

function appTypeFetch(data) {
  return API.appTypeList(data);
}
function appTypeAdd(data) {
  return API.appTypeAdd(data);
}
function appTypeRemove(id) {
  return API.appTypeDel(id);
}
function appTypeUpdate({ id, data }) {
  return API.appTypeEdit(id, data);
}
function* appTypeList(action) {
  const { callback } = action;
  try {
    const res = yield call(appTypeFetch, action.payload);

    if (res.code === 10000) {
      yield put(appTypeListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(appTypeListError(res.message));
    }
  } catch (e) {
    yield put(appTypeListError('获取软件信息数据出错'));
  }
}
function* appTypeRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(appTypeRemove, payload);
    if (res.code === 10000) {
      message.success(`删除软件操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除软件操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除软件操作失败！`);
  }
}
function* appTypeUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(appTypeUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑软件操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑软件操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑软件操作失败！`);
  }
}
function* appTypeAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(appTypeAdd, payload);

    if (res.code === 10000) {
      message.success(`添加软件操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加软件操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加软件操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(APP_TYPE_LIST_REQUEST, appTypeList),
    takeLatest(APP_TYPE_ADD_REQUEST, appTypeAddRequest),
    takeLatest(APP_TYPE_UPDATE_REQUEST, appTypeUpdateRequest),
    takeLatest(APP_TYPE_REMOVE_REQUEST, appTypeRemoveRequest),
  ]);
}
