export const API_ROOT = '/mgmt';
export const API_AUTH = '/auth';
export const UPLOAD_ROOT = '/mgmtUpload';
export const Api = {
  Code: '/vCode',
  Login: '/login',
  Logout: '/logout',
  MenusBuild: '/menus/build',
  Menus: '/menus',
  MenusTree: '/menus/tree',
  Account: '/sysUsers',
  AccountInfo: '/sysUsers/info',
  Roles: '/roles',
  RolesTree: 'roles/tree',
  Permissions: '/permissions',
  PermissionsTree: '/permissions/tree',
  Activity: '/activity',
  Users: '/users',
  Password: '/sysUsers/updatePass',
  Resources: '/resources',
  Medals: '/medals',
  Vendors: '/app/mfr',
  Update: '/update',
  AppType: '/app/type',
  AppModel: '/app/model',
};
