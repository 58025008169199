import {
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAILURE,
  VENDOR_ADD_SUCCESS,
  VENDOR_UPDATE_SUCCESS,
  VENDOR_REMOVE_SUCCESS
} from '../../constants/ActionsType';
import { message } from 'antd';

const initState = {
  list: [],
  loading: false,
  total: 0
};

function vendorReducer(state = initState, action) {
  switch (action.type) {
    case VENDOR_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case VENDOR_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        loading: false,
        total: action.payload.total
      };
    case VENDOR_LIST_FAILURE:
      message.error(action.payload);
      return {
        ...state,
        loading: false
      };
    case VENDOR_REMOVE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload.id)
      };
    case VENDOR_ADD_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload)
      };
    case VENDOR_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            Object.keys(action.payload).forEach(key => {
              item[key] = action.payload[key];
            });
            return item;
          }
          return item;
        })
      };
    default:
      return state;
  }
}

export default vendorReducer;
