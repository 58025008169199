export function formatter(menu, parentPath = '/') {
  return menu.map(item => {
    const result = {
      ...item,
      path: parentPath + item.path
    };
    if (item.children && item.children.length) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`);
    }
    return result;
  });
}

export const regenerateMenuData = data => {
  return data.map(item => {
    let obj = {
      ...item,
      value: item.name,
      title: item.name,
      path: item.path
    };
    if (item.children && item.children.length) {
      obj.children = regenerateMenuData(item.children);
    }
    return obj;
  });
};
