import {
  RESOURCES_LIST_REQUEST,
  RESOURCES_LIST_SUCCESS,
  RESOURCES_LIST_FAILURE,
  RESOURCES_ADD_REQUEST,
  RESOURCES_ADD_SUCCESS,
  RESOURCES_UPDATE_REQUEST,
  RESOURCES_UPDATE_SUCCESS,
  RESOURCES_REMOVE_REQUEST,
  RESOURCES_REMOVE_SUCCESS
} from '../../constants/ActionsType';

export const resourcesListRequest = (data, callback) => {
  return { type: RESOURCES_LIST_REQUEST, payload: data, callback };
};
export const resourcesListSuccess = data => {
  return { type: RESOURCES_LIST_SUCCESS, payload: data };
};
export const resourcesListError = data => {
  return { type: RESOURCES_LIST_FAILURE, payload: data };
};
export const resourcesAddRequest = (data, callback) => {
  return { type: RESOURCES_ADD_REQUEST, payload: data, callback };
};
export const resourcesAddSuccess = data => {
  return { type: RESOURCES_ADD_SUCCESS, payload: data };
};
export const resourcesUpdateRequest = (data, callback) => {
  return { type: RESOURCES_UPDATE_REQUEST, payload: data, callback };
};
export const resourcesUpdateSuccess = data => {
  return { type: RESOURCES_UPDATE_SUCCESS, payload: data };
};
export const resourcesRemoveRequest = (data, callback) => {
  return { type: RESOURCES_REMOVE_REQUEST, payload: data, callback };
};
export const resourcesRemoveSuccess = data => {
  return { type: RESOURCES_REMOVE_SUCCESS, payload: data };
};
