import {
  MENUS_LIST_REQUEST,
  MENUS_LIST_SUCCESS,
  MENUS_LIST_FAILURE,
  MENUS_ADD_REQUEST,
  MENUS_ADD_SUCCESS,
  MENUS_UPDATE_REQUEST,
  MENUS_UPDATE_SUCCESS,
  MENUS_REMOVE_REQUEST,
  MENUS_REMOVE_SUCCESS,
  MENUS_TREE_LIST_REQUEST,
  MENUS_TREE_LIST_SUCCESS,
  MENUS_TREE_LIST_FAILURE
} from '../../constants/ActionsType';

export const menusListRequest = (data, callback) => {
  return { type: MENUS_LIST_REQUEST, payload: data, callback };
};
export const menusListSuccess = data => {
  return { type: MENUS_LIST_SUCCESS, payload: data };
};
export const menusListError = data => {
  return { type: MENUS_LIST_FAILURE, payload: data };
};
export const menusAddRequest = (data, callback) => {
  return { type: MENUS_ADD_REQUEST, payload: data, callback };
};
export const menusAddSuccess = data => {
  return { type: MENUS_ADD_SUCCESS, payload: data };
};
export const menusUpdateRequest = (data, callback) => {
  return { type: MENUS_UPDATE_REQUEST, payload: data, callback };
};
export const menusUpdateSuccess = data => {
  return { type: MENUS_UPDATE_SUCCESS, payload: data };
};
export const menusRemoveRequest = (data, callback) => {
  return { type: MENUS_REMOVE_REQUEST, payload: data, callback };
};
export const menusTreeRequest = () => {
  return { type: MENUS_TREE_LIST_REQUEST, payload: '' };
};
export const menusTreeListSuccess = data => {
  return { type: MENUS_TREE_LIST_SUCCESS, payload: data };
};
export const menusTreeListError = data => {
  return { type: MENUS_TREE_LIST_FAILURE, payload: data };
};
export const menusRemoveSuccess = data => {
  return { type: MENUS_REMOVE_SUCCESS, payload: data };
};
