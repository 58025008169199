import {
  UPDATE_HTTP_STATUS,
  RESET_HTTP_STATUS
} from '../../constants/ActionsType';

const initState = {
  httpStatus: null
};

export default function networkReducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_HTTP_STATUS:
      return {
        httpStatus: action.status
      };
    case RESET_HTTP_STATUS:
      return initState;
    default:
      return state;
  }
}
