import pathToRegexp from 'path-to-regexp';
import Loadable from '../components/Loading';
function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children && item.children.length > 0) {
      keys[item.path] = {
        ...item,
      };
      keys = {
        ...keys,
        ...getFlatMenuData(item.children),
      };
    } else {
      keys[item.path] = {
        ...item,
      };
    }
  });
  return keys;
}
// 打开路由时，设置正确的菜单项的key高亮值；主要针对访问二级路由页面时，设置正确的左侧菜单项高亮值；
export const menuOpenKeyAncestorMap = {};

// 设置菜单项的默认跳转路由，例如：打开固件，则实际跳转到firmware/list页面，主要针对左侧菜单项只有一条高亮，而内容页面会细分二级菜单项
export const redirectRoutes = [
  {
    from: '/update',
    to: '/update/update-list',
  },
];
export const getRouterData = (permissionsMenuData) => {
  // routerConfig��·��˳��Ͳ˵�չʾ��˳�򱣳�һ�£����򣬻ᵼ�µ�¼���ض���ҳ������쳣��
  const routerConfig = {
    '/': {
      component: Loadable({
        loader: () => import('../layouts/BasicLayout'),
      }),
    },
    '/resource': {
      component: Loadable({
        loader: () => import('../containers/Resources'),
      }),
    },
    '/system/menus': {
      component: Loadable({
        loader: () => import('../containers/System/Menus'),
      }),
    },
    '/system/roles': {
      component: Loadable({
        loader: () => import('../containers/System/Roles'),
      }),
    },
    '/system/permission': {
      component: Loadable({
        loader: () => import('../containers/System/Permissions'),
      }),
    },
    '/system/account': {
      component: Loadable({
        loader: () => import('../containers/System/Users'),
      }),
    },
    '/vendors': {
      component: Loadable({
        loader: () => import('../containers/Vendor'),
      }),
    },
    '/models': {
      component: Loadable({
        loader: () => import('../containers/Model'),
      }),
    },
    '/app-types': {
      component: Loadable({
        loader: () => import('../containers/AppType'),
      }),
    },
    '/update/update-list': {
      component: Loadable({
        loader: () => import('../containers/Update'),
      }),
    },
    '/update/mac/:id': {
      component: Loadable({
        loader: () => import('../containers/Update/mac'),
      }),
    },
    '/update/model-config/:id': {
      component: Loadable({
        loader: () => import('../containers/Update/model'),
      }),
    },
    '/posters/list': {
      component: Loadable({
        loader: () => import('../containers/Poster'),
      }),
    },
    '/posters/vendors': {
      component: Loadable({
        loader: () => import('../containers/Poster/Vendors'),
      }),
    },
    '/posters/models': {
      component: Loadable({
        loader: () => import('../containers/Poster/Models'),
      }),
    },
    '/kugou/accounts': {
      component: Loadable({
        loader: () => import('../containers/Kugou/accounts'),
      }),
    },
    '/kugou/records': {
      component: Loadable({
        loader: () => import('../containers/Kugou/records'),
      }),
    },
    '/logs': {
      component: Loadable({
        loader: () => import('../containers/Log'),
      }),
    },
    '/login': {
      component: Loadable({
        loader: () => import('../layouts/LoginLayout'),
      }),
    },
  };
  // Get name from ./menu.js or just set it in the router data.
  const menuData = permissionsMenuData ? getFlatMenuData(permissionsMenuData) : [];

  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find((key) => pathRegexp.test(`${key}`));

    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: permissionsMenuData && menuKey ? true : false,
      // hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb
    };
    routerData[path] = router;
  });
  return routerData;
};
