import {
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAILURE,
  VENDOR_ADD_REQUEST,
  VENDOR_ADD_SUCCESS,
  VENDOR_UPDATE_REQUEST,
  VENDOR_UPDATE_SUCCESS,
  VENDOR_REMOVE_REQUEST,
  VENDOR_REMOVE_SUCCESS
} from '../../constants/ActionsType';

export const vendorListRequest = (data, callback) => {
  return { type: VENDOR_LIST_REQUEST, payload: data, callback };
};
export const vendorListSuccess = data => {
  return { type: VENDOR_LIST_SUCCESS, payload: data };
};
export const vendorListError = data => {
  return { type: VENDOR_LIST_FAILURE, payload: data };
};
export const vendorAddRequest = (data, callback) => {
  return { type: VENDOR_ADD_REQUEST, payload: data, callback };
};
export const vendorAddSuccess = data => {
  return { type: VENDOR_ADD_SUCCESS, payload: data };
};
export const vendorUpdateRequest = (data, callback) => {
  return { type: VENDOR_UPDATE_REQUEST, payload: data, callback };
};
export const vendorUpdateSuccess = data => {
  return { type: VENDOR_UPDATE_SUCCESS, payload: data };
};
export const vendorRemoveRequest = (data, callback) => {
  return { type: VENDOR_REMOVE_REQUEST, payload: data, callback };
};
export const vendorRemoveSuccess = data => {
  return { type: VENDOR_REMOVE_SUCCESS, payload: data };
};
