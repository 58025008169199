import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  POSTER_VENDOR_LIST_REQUEST,
  POSTER_VENDOR_ADD_REQUEST,
  POSTER_VENDOR_UPDATE_REQUEST,
  POSTER_VENDOR_REMOVE_REQUEST,
} from '../../constants/ActionsType';
import { posterVendorListSuccess, posterVendorListError } from '../actions/poster-vendor';

function posterVendorFetch(data) {
  return API.posterVendorList(data);
}
function posterVendorAdd(data) {
  return API.posterVendorAdd(data);
}
function posterVendorRemove(id) {
  return API.posterVendorDel(id);
}
function posterVendorUpdate({ id, data }) {
  return API.posterVendorEdit(id, data);
}
function* posterVendorList(action) {
  const { callback } = action;
  try {
    const res = yield call(posterVendorFetch, action.payload);

    if (res.code === 10000) {
      yield put(posterVendorListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(posterVendorListError(res.message));
    }
  } catch (e) {
    yield put(posterVendorListError('获取机型信息数据出错'));
  }
}
function* posterVendorRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(posterVendorRemove, payload);
    if (res.code === 10000) {
      message.success(`删除机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除机型操作失败！`);
  }
}
function* posterVendorUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(posterVendorUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑机型操作失败！`);
  }
}
function* posterVendorAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(posterVendorAdd, payload);

    if (res.code === 10000) {
      message.success(`添加机型操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加机型操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加机型操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(POSTER_VENDOR_LIST_REQUEST, posterVendorList),
    takeLatest(POSTER_VENDOR_ADD_REQUEST, posterVendorAddRequest),
    takeLatest(POSTER_VENDOR_UPDATE_REQUEST, posterVendorUpdateRequest),
    takeLatest(POSTER_VENDOR_REMOVE_REQUEST, posterVendorRemoveRequest),
  ]);
}
