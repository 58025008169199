import {
  POSTER_MODEL_LIST_REQUEST,
  POSTER_MODEL_LIST_SUCCESS,
  POSTER_MODEL_LIST_FAILURE,
  POSTER_MODEL_ADD_SUCCESS,
  POSTER_MODEL_UPDATE_SUCCESS,
  POSTER_MODEL_REMOVE_SUCCESS,
  POSTER_MODEL_RESET_REQUEST,
} from '../../constants/ActionsType';
import { message } from 'antd';

const initState = {
  list: [],
  loading: false,
  total: 0,
};

function posterModelReducer(state = initState, action) {
  switch (action.type) {
    case POSTER_MODEL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POSTER_MODEL_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        loading: false,
        total: action.payload.total,
      };
    case POSTER_MODEL_LIST_FAILURE:
      message.error(action.payload);
      return {
        ...state,
        loading: false,
      };
    case POSTER_MODEL_REMOVE_SUCCESS:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.payload.id),
      };
    case POSTER_MODEL_ADD_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload),
      };
    case POSTER_MODEL_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === action.payload.id) {
            Object.keys(action.payload).forEach((key) => {
              item[key] = action.payload[key];
            });
            return item;
          }
          return item;
        }),
      };
    case POSTER_MODEL_RESET_REQUEST:
      return initState;
    default:
      return state;
  }
}

export default posterModelReducer;
