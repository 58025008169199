import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user';
import slideMenu from './slide-menu';
import networkReducer from './network';
import resources from './resources';
import role from './role';
import permission from './permission';
import menu from './menu';
import vendor from './vendor';
import appModel from './app-model';
import appType from './app-type';
import posterModel from './poster-model';
import posterVendor from './poster-vendor';

const config = {
  key: 'appUpdate',
  storage,
  whitelist: ['userReducer', 'slideMenu', 'menu'],
};
const rootReducer = persistCombineReducers(config, {
  userReducer,
  networkReducer,
  slideMenu,
  menu,
  role,
  resources,
  permission,
  vendor,
  appModel,
  appType,
  posterModel,
  posterVendor,
});

export default rootReducer;
