import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import API from '../../api';
import {
  VENDOR_LIST_REQUEST,
  VENDOR_ADD_REQUEST,
  VENDOR_UPDATE_REQUEST,
  VENDOR_REMOVE_REQUEST
} from '../../constants/ActionsType';
import { vendorListSuccess, vendorListError } from '../actions/vendor';

function vendorFetch(data) {
  return API.vendorsList(data);
}
function vendorAdd(data) {
  return API.vendorsAdd(data);
}
function vendorRemove(id) {
  return API.vendorsDel(id);
}
function vendorUpdate({ id, data }) {
  return API.vendorsEdit(id, data);
}
function* vendorList(action) {
  const { callback } = action;
  try {
    const res = yield call(vendorFetch, action.payload);

    if (res.code === 10000) {
      yield put(vendorListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(vendorListError(res.message));
    }
  } catch (e) {
    yield put(vendorListError('获取厂商信息数据出错'));
  }
}
function* vendorRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(vendorRemove, payload);
    if (res.code === 10000) {
      message.success(`删除厂商操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`删除厂商操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`删除厂商操作失败！`);
  }
}
function* vendorUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(vendorUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑厂商操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`编辑厂商操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`编辑厂商操作失败！`);
  }
}
function* vendorAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(vendorAdd, payload);

    if (res.code === 10000) {
      message.success(`添加厂商操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      message.error(`添加厂商操作失败，${res.message}`);
    }
  } catch (e) {
    message.error(`添加厂商操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(VENDOR_LIST_REQUEST, vendorList),
    takeLatest(VENDOR_ADD_REQUEST, vendorAddRequest),
    takeLatest(VENDOR_UPDATE_REQUEST, vendorUpdateRequest),
    takeLatest(VENDOR_REMOVE_REQUEST, vendorRemoveRequest)
  ]);
}
