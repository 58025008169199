import {
  APP_TYPE_LIST_REQUEST,
  APP_TYPE_LIST_SUCCESS,
  APP_TYPE_LIST_FAILURE,
  APP_TYPE_ADD_REQUEST,
  APP_TYPE_ADD_SUCCESS,
  APP_TYPE_UPDATE_REQUEST,
  APP_TYPE_UPDATE_SUCCESS,
  APP_TYPE_REMOVE_REQUEST,
  APP_TYPE_REMOVE_SUCCESS,
} from '../../constants/ActionsType';

export const appTypeListRequest = (data, callback) => {
  return { type: APP_TYPE_LIST_REQUEST, payload: data, callback };
};
export const appTypeListSuccess = data => {
  return { type: APP_TYPE_LIST_SUCCESS, payload: data };
};
export const appTypeListError = data => {
  return { type: APP_TYPE_LIST_FAILURE, payload: data };
};
export const appTypeAddRequest = (data, callback) => {
  return { type: APP_TYPE_ADD_REQUEST, payload: data, callback };
};
export const appTypeAddSuccess = data => {
  return { type: APP_TYPE_ADD_SUCCESS, payload: data };
};
export const appTypeUpdateRequest = (data, callback) => {
  return { type: APP_TYPE_UPDATE_REQUEST, payload: data, callback };
};
export const appTypeUpdateSuccess = data => {
  return { type: APP_TYPE_UPDATE_SUCCESS, payload: data };
};
export const appTypeRemoveRequest = (data, callback) => {
  return { type: APP_TYPE_REMOVE_REQUEST, payload: data, callback };
};
export const appTypeRemoveSuccess = data => {
  return { type: APP_TYPE_REMOVE_SUCCESS, payload: data };
};
