import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Exception from 'ant-design-pro/lib/Exception';
import styles from './NotFound.less';
import 'ant-design-pro/dist/ant-design-pro.css';
import { resetHttpStatus } from '../../redux/actions/network';
import n403 from '../../assets/imgs/403.svg';

class NoAuth extends React.Component {
  handleReturnClick = () => {
    this.props.history.push('/');
  };
  componentDidMount() {
    this.props.resetHttpStatus();
  }
  render() {
    const actions = (
      <Button type="primary" onClick={this.handleReturnClick}>
        回到首页
      </Button>
    );
    return (
      <div className={styles.nomatch}>
        <Helmet title="403" />
        <Exception type="403" img={n403} actions={actions} />
      </div>
    );
  }
}
const mapDispatchToProp = dispatch => ({
  resetHttpStatus: () => {
    dispatch(resetHttpStatus());
  }
});
export default connect(
  null,
  mapDispatchToProp
)(NoAuth);
