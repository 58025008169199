// saga 模块化引入
import { fork, all } from 'redux-saga/effects';

// 异步逻辑
import user from './user';
import resources from './resources';
import menu from './menu';
import role from './role';
import vendor from './vendor';
import appType from './app-type';
import appModel from './app-model';
import permission from './permission';
import posterVendor from './poster-vendor';
import posterModel from './poster-model';

// 单一进入点，一次启动所有 Saga
export default function* rootSaga() {
  yield all([
    fork(user),
    fork(resources),
    fork(menu),
    fork(role),
    fork(vendor),
    fork(appType),
    fork(appModel),
    fork(permission),
    fork(posterVendor),
    fork(posterModel),
  ]);
}
