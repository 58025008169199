import {
  POSTER_VENDOR_LIST_REQUEST,
  POSTER_VENDOR_LIST_SUCCESS,
  POSTER_VENDOR_LIST_FAILURE,
  POSTER_VENDOR_ADD_REQUEST,
  POSTER_VENDOR_ADD_SUCCESS,
  POSTER_VENDOR_UPDATE_REQUEST,
  POSTER_VENDOR_UPDATE_SUCCESS,
  POSTER_VENDOR_REMOVE_REQUEST,
  POSTER_VENDOR_REMOVE_SUCCESS,
  POSTER_VENDOR_RESET_REQUEST,
} from '../../constants/ActionsType';

export const posterVendorListRequest = (data, callback) => {
  return { type: POSTER_VENDOR_LIST_REQUEST, payload: data, callback };
};
export const posterVendorListSuccess = (data) => {
  return { type: POSTER_VENDOR_LIST_SUCCESS, payload: data };
};
export const posterVendorListError = (data) => {
  return { type: POSTER_VENDOR_LIST_FAILURE, payload: data };
};
export const posterVendorAddRequest = (data, callback) => {
  return { type: POSTER_VENDOR_ADD_REQUEST, payload: data, callback };
};
export const posterVendorAddSuccess = (data) => {
  return { type: POSTER_VENDOR_ADD_SUCCESS, payload: data };
};
export const posterVendorUpdateRequest = (data, callback) => {
  return { type: POSTER_VENDOR_UPDATE_REQUEST, payload: data, callback };
};
export const posterVendorUpdateSuccess = (data) => {
  return { type: POSTER_VENDOR_UPDATE_SUCCESS, payload: data };
};
export const posterVendorRemoveRequest = (data, callback) => {
  return { type: POSTER_VENDOR_REMOVE_REQUEST, payload: data, callback };
};
export const posterVendorRemoveSuccess = (data) => {
  return { type: POSTER_VENDOR_REMOVE_SUCCESS, payload: data };
};
export const posterVendorResetRequest = () => {
  return { type: POSTER_VENDOR_RESET_REQUEST };
};
